.card {
    height: 280px;
  }
  
  .info {
    display: flex;
    align-items: center;
    padding: 5px;
    font-weight: 500;
    font-size: 14px;
  }
  
  .userImg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .postImg{
      width: 100%;
      height: 200px;
      object-fit: cover;
  }
  
  .interaction{
      display: flex;
      align-items: center;
      padding: 5px 10px;
      position: relative;
  }
  
  .cardIcon{
      width: 16px;
      height: 16px;
      cursor: pointer;
      margin-right: 10px;
  }
  
  .infoIcon{
      position: absolute;
      right: 0;
      width: 12px;
      height: 12px;
  }
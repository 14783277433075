.Card {
display: flex;
align-items: center;
justify-content: center;
}

.centered {
    height: 10vh; /* Magic here */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .block-example {
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
.custom-navbar-toggle {
  display: block !important; 
  color: black;/* Force the button to be displayed */
}
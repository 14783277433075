.aboutUsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }
  
  .header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .title {
    font-size: 3rem;
    font-weight: 600;
  }
  
  .aboutUsSection {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  
  .description {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 2rem;
  }
  .website {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 2rem;
  }
  .businessType {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 2rem;
  }
  
  .addressContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
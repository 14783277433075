/* blogPage.css */
.blog-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .blog-subtitle {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .blog-subtitle-text {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .blog-subtitle-item {
    font-size: 16px;
    margin-left: 20px;
    margin-bottom: 10px;
  }
  
  .blog-conclusion-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .blog-conclusion-text {
    font-size: 18px;
  }
  
.aboutus {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .aboutus h1 {
    font-size: 3rem;
    margin-bottom: 30px;
  }
  
  .aboutus h5 {
    font-size: 1.5rem;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .aboutus p {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .meetup {
    height: 40%;
    width: 40%; 
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .img {
    height: 300px;
    width: 500px; 
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .users {
    height: 300px;
    width: 300px; 
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .ws {
    height: 350px;
    width: 250px; 
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
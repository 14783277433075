.sidebar {
  flex: 1;
  height: calc(100vh - 50px);
  background-color: #f5f5f5;
  position: sticky;
  top: 50px;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: #999;
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: #e0e0e0;
}

.sidebarIcon {
  margin-right: 10px;
  font-size: 20px !important;
  color: #333;
}

button {
  background: transparent;
  box-shadow: none;
  border: none;
  text-shadow: none;
}

button:hover {
  background-color: transparent;
}

button:focus,
button:active {
  outline: none;
}

@media (max-width: 600px) {
  .sidebar {
    display: none;
  }
}


.page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .split-view {
    display: flex;
    flex: 1;
    position: relative;
  }
  
  .left-panel {
    flex: 0 0 600px; /* Adjust the width as needed */
    height: 600px;
    padding: 20px;
    background-color: #f5f5f5;
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  .right-panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    padding: 20px;
    background-color: #ffffff;
  }
  .text {
    white-space: pre-wrap;
  }
  .editor-panel {
    display: flex;
    overflow-x: auto; /* Add this to enable horizontal scrolling if needed */
  }
  
  .editor-wrapper {
    flex: 1;
    margin-right: 20px;
  }
  
  .editor-wrapper.active {
    border: 2px solid blue; /* Add a border to highlight the active editor */
  }

  /* Add media query for mobile layout */
@media (max-width: 600px) {
  .split-view {
    flex-direction: column;
  }

  .right-panel {
    position: relative;
    width: 100%;
  }

  .editor-panel {
    flex-direction: row;
    overflow-x: auto;
  }

  .editor-wrapper {
    flex: 1;
    margin-right: 20px;
    margin-bottom: 0;
  }
}
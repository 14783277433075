@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
.map-container {
    height: 400px;
  }
  /* App.css */
.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex-grow: 1;
}

footer {
  flex-shrink: 0;
}


.resources a {
    color: black;
}

h1 {
    color: black;
}

.resourcespic {
    height: 350px;
    width: 450px; 

}
.topbar {
  width: 100%;
  height: 100px; /* Updated height to accommodate the padding */
  background-color: rgb(255, 255, 255);
  position: sticky;
  top: 0;
  z-index: 999;
  padding-top: 0px; /* 20% of the original height */
}



.topbarWrapper {
  height: 0%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 30px;
  color: black;
  cursor: pointer;
}

.topRight {
  display: flex;
  align-items: left;
  padding-top: 45px;
}

.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}

.topIconBadge {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.topAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

/* Media queries for mobile devices */
@media screen and (max-width: 600px) {
  .topbarWrapper {
    padding: 0px 10px;
  }
  
  .logo {
    font-size: 24px;
  }
  
  .topIconBadge {
    width: 10px;
    height: 10px;
    top: -3px;
    right: -5px;
    font-size: 8px;
  }
  
  .topAvatar {
    width: 30px;
    height: 30px;
  }

  .topbarIconContainer {
    margin-right: 0;
  }

  .topbarIconContainer svg {
    font-size: 24px;
  }
  
  .topIconBadge {
    width: 8px;
    height: 8px;
    top: -2px;
    right: -4px;
    font-size: 6px;
  }
}

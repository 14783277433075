.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    margin: 50px;
  }
  
  h1 {
    margin-bottom: 30px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  label {
    margin-bottom: 10px;
  }
  
  button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4caf50;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }

.home {
    color: white;
}

.meetup {
    height: 50%;
    width: 50%; 
}

.links {
    justify-content: space-between;
    color: white;

}

.chat-input-textarea {
background-color: gray;
width: 100%;
padding: 12px;
border-radius:5px;
border: none;
margin:12px;
outline: none;
color: white;
box-shadow: 0 8px 0 rgba(0,0,0,0.5);
}


  